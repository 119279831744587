/**
 * try to prefix the methods of the service with the method
 * that will execute the call, as in get/post/delete/update
 */

import axios from 'axios'

const apiClient = axios.create({
    //baseURL: `http://localhost:8001/`,
    baseURL: process.env.VUE_APP_V1_URL,
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
})


export default {
    async postReport (payload) {
        return apiClient.post('report/', payload)
    }
}
