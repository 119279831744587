/**
 * try to prefix the methods of the service with the method
 * that will execute the call, as in get/post/delete/update
 */

import axios from 'axios'

const apiClient = axios.create({
    //baseURL: `http://localhost:8001/`,
    //baseURL: `http://192.168.2.189:8001/`,
    //baseURL: `https://admin.wildlifepoisoning.lifeneophron.eu/`,
    baseURL: process.env.VUE_APP_V1_URL,
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
})


export default {
    async getIncidentsPerYear () {
        return apiClient.get('stats/incperyear')
    },
    async getIncidentsPerMonth () {
        return apiClient.get('stats/incpermonth')
    },
    async getIncComplaints () {
        return apiClient.get('stats/complaints')
    },
    async getIncCourt () {
        return apiClient.get('stats/court')
    },
    async getIncAutopsy () {
        return apiClient.get('stats/autopsy')
    },
    async getIncAnimals () {
        return apiClient.get('stats/animals')
    },
    async getIncToxins () {
        return apiClient.get('stats/toxins')
    }
}
