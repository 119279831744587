// Pathify
import { make } from 'vuex-pathify'
import PageService from '../../services/PageService'

const state = {
  loading: false,
  pages: {},
  page: {},
  error: ""
}

const mutations = {
  ...make.mutations(state),
  CUPAGES (state, {payload, loc, lang}) {
    // Check if the state already has the key
    // for this location. If not, create a new object
    // to keep the country codes and then associate
    // with the payload.
    if (!Object.prototype.hasOwnProperty.call(state.pages, loc)) {
      state.pages[loc] = {}
    }
    state.pages[loc][lang] = payload
  },
  SET_PAGE (state, {location, locale}) {
    state.page = state.pages[location][locale]
  }
}

const actions = {
    ...make.actions(state),
    async fetchPage({commit, state}, {location, locale}) {

      // Check first if we have the page on the state
      if (Object.prototype.hasOwnProperty.call(state.pages, location)) {
        // check if the location already has the language in place
        if (Object.prototype.hasOwnProperty.call(state.pages[location], locale)) {
          commit('SET_PAGE', {location, locale})
          return true
        }
      }

      let page_id = 101
      switch (location) {
        case 'about':
          page_id = 101
          break
        case 'wildlifePoisoning':
          page_id = 102
          break
        case 'antipoison':
          page_id = 103
          break
        case 'majorPoisonIncidents':
          page_id = 104
          break
        case 'links':
          page_id = 105
          break
        case 'disclaimer':
          page_id = 106
      }

      try {
        commit('loading', true)
        let response = await PageService.getPage(page_id,locale) // eslint-disable-line
        commit('CUPAGES', {payload: response.data, loc: location, lang: locale})
        commit('SET_PAGE', {location, locale})
        commit('loading', false)
      } catch (error) {
        commit('loading', false)
        commit('error', error)
      }
    },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
