<template>
    <v-menu class="toolbar-menu-item" 
        offset-y
        origin="center center" 
        :nudge-bottom="10" 
        transition="scale-transition" 
        content-class="language-menu" 
        z-index=1301
    >
        <template v-slot:activator="{ on }">
        <v-btn icon large text v-on="on" :ripple="false" color="white darken-2">
            <!-- <img :src="selectedLanguageFlag" class="selected-language-flag" /> -->
            {{ selectedLanguageText }}
        </v-btn>
        </template>
        <v-list class="languages-list">
            <v-list-item
                v-for="(language, index) in languages"
                @click="selectLanguage(language.languageCode)"
                :key="index"
                class="languages-list-item"
            >
                <v-list-item-action v-if="language.path">
                    <!-- <img :src="language.path" class="language-flag" /> -->
                    {{ language.languageCode }}
                </v-list-item-action>
                <v-list-item-content class="languages-list-item-title">
                    <v-list-item-title>{{ language.name }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    data() {
        return {
            languages: [
                // { name: 'English', languageCode: 'en', path: require('@/assets/flags/en.png')},
                { name: 'Albanian', languageCode: 'al', path: "a"},
                { name: 'Bosnian', languageCode: 'ba', path: "a"},
                { name: 'Bulgarian', languageCode: 'bg', path: "a"},
                { name: 'Croatian', languageCode: 'hr', path: "a"},
                { name: 'English', languageCode: 'en', path: "a"},
                { name: 'Greek', languageCode: 'el', path: "a"},
                { name: 'North Macedonian', languageCode: 'mk', path: "a"},
                { name: 'Serbian', languageCode: 'rs', path: "a"},
                
            ]
        }
    },
    computed: {
        selectedLanguageText() {
            switch(this.$i18n.locale) {
                case 'en': return 'EN';
                case 'el': return 'GR';
                case 'bg': return 'BG';
                case 'al': return 'AL';
                case 'ba': return 'BA';
                case 'mk': return 'MK';
                case 'rs': return 'RS';
                case 'hr': return 'HR';
            }
            return "EN"
        }
    },
    methods: {
        selectLanguage(code) {
            const vm = this;

            vm.$root.setLanguage(code);
        }
    }
}
</script>


<style>
.selected-language-flag {
    max-width: 45px;
}
.language-flag {
    max-width: 40px;
}
.languages-list-item {
    cursor: pointer;
    margin-top: -2px;
    margin-left: 1px;
}
.languages-list-item-title {
    font-size: 16px;
}
.languages-list-item-title:hover {
    color: #7EB09F;
    font-weight: bold;
}
.language-menu {
    /* border-radius: 25px; */
    /* width: 235px; */
    width: 350x;
    margin-right: 10px;
}
</style>
