import axios from 'axios'

const apiClient = axios.create({
    baseURL: process.env.VUE_APP_V1_URL,
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
})


export default {
    async getPage (id, locale) {
        return apiClient.get(`pages/${id}/${locale}/`)
    }
}
