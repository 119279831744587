// Pathify
// import { make } from 'vuex-pathify'

const state = {
  currentID: '',
}

const mutations = {
    setCurrentID (state, id) {
        state.currentID = id
    }
}

export default {
  namespaced: true,
  state,
  mutations,
//   actions,
//   getters,
}
