// Pathify
import { make } from 'vuex-pathify'
import IndexService from '../../services/IndexService'

const state = {
  loading: false,
  indexes: {},
  error: ""
}

const mutations = make.mutations(state)

const actions = {
    ...make.actions(state),
    async fetchIndexes({commit}) {
      try {
        commit('loading', true)
        // console.log('fetchIndexes - getting the indexes for fun and profit')
        let response = await IndexService.getIndexes() // eslint-disable-line
        commit('indexes', response.data)
        
      } catch (error) {
        commit('error', error)
      } finally {
        commit('loading', false)
      }
    },
}

const getters = {
  indexLoading: state => state.loading
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
