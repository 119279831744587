<template>
  <v-app>
    <v-app-bar
      app
      color="#494a4f"
    >
    <!-- <v-app-bar
      app
      color="biscuit"
      dark
      class="appgradient"
    > -->
      
      <top-burger-menu @gotoPage="loadDialog" />

      <!-- <v-spacer></v-spacer> -->


      <div class="d-flex align-center">
          <div class="">
            &nbsp;
            &nbsp;
          </div>
          <v-spacer/>
          <span class="white--text text--darken-2 text-decoration-none font-weight-black">
              Balkan Wildlife Poisoning Map
          </span>
      </div>

      <v-spacer></v-spacer>

      <language-menu />
    </v-app-bar>

    <!-- CONSTRUCTION YARD -->
    <!-- <construction-yard v-if="debug" /> -->

    <v-main>
      <!-- <MainMap /> -->
      <!-- <MapMarkers @showDisclaimer="disclaimer=true" /> -->
      <v-card v-if="indexLoading">
        <v-card-title>Please wait while map and data is loading</v-card-title>
        <v-card-text>
          <div class="d-flex align-center">
          <v-progress-circular indeterminate size="100" class="align-center"/>
          </div>
        </v-card-text>
      </v-card>
      <MapMarkers @showDisclaimer="setDisclaimer" @showReport="showReport" v-else/>
    </v-main>

    <!-- SIDE PANELS -->
    <disclaimer @showDisclaimer="setDisclaimer" />
    <legend-info />

    <!-- REPORT PAGE ---> 
    <v-dialog
      v-model="report"
      max-width="80%"
      class="dialogos"
      overlay-opacity="0.7"
    >
      <show-report @closedialog="report=false" @report-complete="report=false"/>
    </v-dialog>

    <!-- ----------- PAGES ----------- -->
    <!-- Disclaimer -->
    <v-dialog
      v-model="disclaimer"
      max-width="70%"
      class="dialogos"
      overlay-opacity="0.7"
    >
      <my-disclaimer @closedialog="disclaimer=false" />
    </v-dialog>

    <!-- ABOUT -->
    <v-dialog
      v-model="about"
      max-width="80%"
      class="dialogos"
      overlay-opacity="0.7"
    >
      <my-about @closedialog="about=false" />
    </v-dialog>

    <!-- Major Poison Incidents -->
    <v-dialog 
      v-model="majorPoisonIncidents" 
      max-width="80%" 
      class="dialogos"
      overlay-opacity="0.7"
    >
      <major-poison-incidents @closedialog="majorPoisonIncidents=false"/>
    </v-dialog>

    <!-- Major Poison Incidents -->
    <v-dialog 
      v-model="antipoison" 
      max-width="80%" 
      class="dialogos"
      overlay-opacity="0.7"
    >
      <antipoison @closedialog="antipoison=false"/>
    </v-dialog>

    <!-- Wildlife Poisoning -->
    <v-dialog 
      v-model="wildlifePoisoning" 
      max-width="80%" 
      class="dialogos"
      overlay-opacity="0.7"
    >
      <wildlife-poisoning @closedialog="wildlifePoisoning=false"/>
    </v-dialog>

    <!-- Statistics -->
    <v-dialog 
      v-model="statistics" 
      max-width="85%" 
      class="dialogos"
      overlay-opacity="0.7"
    >
      <statistics @closedialog="statistics=false"/>
    </v-dialog>

    <!-- Links -->
    <v-dialog 
      v-model="links" 
      max-width="80%" 
      class="dialogos"
      overlay-opacity="0.7"
    >
      <my-links @closedialog="links=false"/>
    </v-dialog>

    <!-- Organizations -->
    <v-dialog 
      v-model="organizations" 
      max-width="80%" 
      class="dialogos">
      <organizations @closedialog="organizations=false"/>
    </v-dialog>

  </v-app>
</template>

<script>
import MapMarkers from './components/MapMarkers.vue';
import TopBurgerMenu from './components/TobBurgerMenu.vue';
import LanguageMenu from './components/LanguageMenu.vue'
// import ConstructionYard from './components/ConstructionYard.vue'
import { get } from 'vuex-pathify'

export default {
  name: 'App',

  components: {
    MapMarkers,
    TopBurgerMenu,
    LanguageMenu,
    // ConstructionYard,
    'my-about': () => import('./components/pages/About.vue'),
    'major-poison-incidents': () => import('./components/pages/MajorPoisonIncidents.vue'),
    'antipoison': () => import('./components/pages/Antipoison.vue'),
    'wildlife-poisoning': () => import('./components/pages/WildlifePoisoning.vue'),
    'statistics': () => import('./components/pages/Statistics.vue'),
    'my-links': () => import('./components/pages/Links.vue'),
    'organizations': () => import('./components/pages/Organizations.vue'),
    'disclaimer': () => import('./components/Disclaimer.vue'),
    'legend-info': () => import('./components/LegendInfo.vue'),
    'my-disclaimer': () => import('./components/pages/Disclaimer.vue'),
    'show-report': () => import('./components/Report.vue'),
  },
  data: () => ({
    debug: process.env.VUE_APP_DEBUG,
    about: false,
    majorPoisonIncidents: false,
    antipoison: false,
    resources: false,
    wildlifePoisoning: false,
    statistics: false,
    links: false,
    organizations: false,
    disclaimer: false,
    report: false
  }),
  computed: {
    ...get('indexes', {
      indexLoading: 'indexLoading'
    })
  },
  methods: {
    loadDialog(e) {
      switch (e) {
        case 'about':
          this.about = true
          break;
        case 'majorPoisonIncidents':
          this.majorPoisonIncidents = true
          break;
        case 'antipoison':
          this.antipoison = true
          break;
        case 'resources':
          this.resources = true
          break;
        case 'wildlifePoisoning':
          this.wildlifePoisoning = true
          break;
        case 'statistics':
          this.statistics = true
          break;
        case 'links':
          this.links = true
          break;
        case 'organizations':
          this.organizations = true
          break;
        default:
          break;
      }
    },
    async setDisclaimer () {
      // FIXME: during testing, stop the disclaimer from poping up :D
      await this.$store.dispatch('pages/fetchPage', {location: 'disclaimer', locale: this.$vuetify.lang.current})
      this.disclaimer = true  // FIXME: needs to be true IRL
    },
    showReport () {
      this.report = true
    },
    doCommand(e) {
      let cmd = String.fromCharCode(e.keyCode).toLowerCase();
      if (cmd === 'v') {
        this.debug = this.debug ? false : true
      }
    }
  },
  async mounted () {
    // await this.$store.dispatch('chart/setIncidentperyear') // FIXME
    // await this.$store.dispatch('chart/setIncpermonth')
    // await this.$store.dispatch('chart/setIncComplaints')
    // await this.$store.dispatch('chart/setIncCourt')
    // await this.$store.dispatch('chart/setIncAutopsy')
    this.$store.dispatch('chart/setIncidentperyear') // FIXME
    this.$store.dispatch('chart/setIncpermonth')
    this.$store.dispatch('chart/setIncComplaints')
    this.$store.dispatch('chart/setIncCourt')
    this.$store.dispatch('chart/setIncAutopsy')
    this.$store.dispatch('chart/setIncAnimals')
    this.$store.dispatch('chart/setIncToxins')
  },
  async created () {
    window.addEventListener('keypress', this.doCommand)
    // await this.$store.dispatch('pages/fetchPage', {location: 'disclaimer', locale: this.$vuetify.lang.current})
    this.$store.dispatch('pages/fetchPage', {location: 'disclaimer', locale: this.$vuetify.lang.current})
  },
  destroyed () {
    window.removeEventListener('keypress', this.doCommand)
  }
};
</script>

<style lang='scss'>
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,700&display=swap');
// @import url('https://fonts.googleapis.com/css? family=Comfortaa&display=swap');

$font-family: 'Poppins';
$body-font-family: 'Poppins';
$title-font: 'Poppins';

// .v-application {
//    font-family: $body-font-family, sans-serif !important;
//     .title { // To pin point specific classes of some components
//        font-family: $title-font, sans-serif !important;
//     }
//     .text-h5 {
//       font-family: $title-font, sans-serif !important;
//     }
//     .a {
//       font-family: $title-font, sans-serif !important;
//     }
//  }

.v-application {
  [class*='text-'] {
    color: #36405a;
    font-family: $font-family, sans-serif !important;
  }
  font-family: $font-family, sans-serif !important;
}

.leaflet-container {
  font: $font-family, sans-serif !important;
  font-family: $font-family, sans-serif !important;
}

.appgradient {
  background: linear-gradient(0deg, rgba(126,176,159,1) 0%, rgba(135,196,188,1) 100%);
}

.dialogos {
  z-index: 1302;
}

.amazing {
  // background-color: pink !important;
}

.leaflet-popup-content {
  margin: -1px -2px !important;
}


// .leaflet-popup-close-button {
//   vertical-align: middle;
//     border: none;
//     color: inherit;
//     border-radius: 50%;
//     background: transparent;
//     position: relative;
//     width: 32px;
//     height: 32px;
//     opacity: 0.6;
// }
// .leaflet-popup-close-button:focus,
// .leaflet-popup-close-button:hover {
//   opacity: 1;
//   background: rgba(63, 58, 58, 0.5);
// }
// .leaflet-popup-close-button:active {
//     background: rgba(63, 58, 58, 0.9);
// }
// .leaflet-popup-close-button::before,
// .leaflet-popup-close-button::after {
//     // content: " ";
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     height: 20px;
//     width: 4px;
//     background-color: currentColor;
// }
// .leaflet-popup-close-button::before {
//     transform: translate(-50%, -50%) rotate(45deg);
// }
// .leaflet-popup-close-button::after {
//     transform: translate(-50%, -50%) rotate(-45deg);
// }
</style>
