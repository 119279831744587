// Pathify
import { make } from 'vuex-pathify'
import ChartService from '../../services/ChartService'

const state = {
  incidentperyear: {},
  incpermonth: {},
  animals: {},
  toxins: {},
  loading: false,
  yearloaded: false,
  monthloaded: false,
  complaintsloaded: false,
  courtloaded: false,
  autopsyloaded: false,
  toxinsloaded: false,
  animalsloaded: false,
  complaints: {},
  court: {},
  autopsy: {}
}

const mutations = make.mutations(state)

const actions = {
    ...make.actions(state),
    async setIncidentperyear({ commit }) {
      try {
          // commit('SET_LOADING', true)
          let data = await ChartService.getIncidentsPerYear()
          commit('incidentperyear', data.data)
          commit('yearloaded', true)
          // commit('SET_LOADING', false)
      } catch (error) {
          // commit('SET_LOADING', false)
          console.log('Something went wrong while fetching indexes')
          console.log(error)
      }
    },
    async setIncpermonth({ commit }) {
      try {
        let data = await ChartService.getIncidentsPerMonth()
        commit('incpermonth', data.data)
        commit('monthloaded', true)
      } catch (error) {
        console.log(error)
      }
    },
    async setIncComplaints({ commit }) {
      try {
        let data = await ChartService.getIncComplaints()
        commit('complaints', data.data)
        commit('complaintsloaded', true)
      } catch (error) {
        console.log(error)
      }
    },
    async setIncCourt({ commit }) {
      try {
        let data = await ChartService.getIncCourt()
        commit('court', data.data)
        commit('courtloaded', true)
      } catch (error) {
        console.log(error)
      }
    },
    async setIncAutopsy({ commit }) {
      try {
        let data = await ChartService.getIncAutopsy()
        commit('autopsy', data.data)
        commit('autopsyloaded', true)
      } catch (error) {
        console.log(error)
      }
    },
    async setIncAnimals({ commit }) {
      try {
        let data = await ChartService.getIncAnimals()
        commit('animals', data.data)
        commit('animalsloaded', true)
      } catch (error) {
        console.log(error)
      }
    },
    async setIncToxins({ commit }) {
      try {
        let data = await ChartService.getIncToxins()
        commit('toxins', data.data)
        commit('toxinsloaded', true)
      } catch (error) {
        console.log(error)
      }
    },
    async fetchall({ commit, dispatch }) {
      try {
        await dispatch('setIncidentperyear')
        await dispatch('setIncpermonth')
        commit('loaded', true)
      } catch (error) {
        console.log(error)
      }
    }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
