// Pathify
import { make } from 'vuex-pathify'
import ReportService from '../../services/ReportService'

const state = {
  loading: false,
  report: {}
}

const mutations = make.mutations(state)

const actions = {
    ...make.actions(state),
    async postReport({commit}, report) {
      try {
        commit('loading', true)
        let response = await ReportService.postReport(report) // eslint-disable-line
        commit('loading', false)
      } catch (error) {
        commit('loading', false)
      }
    },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
