// Pathify
import { make } from 'vuex-pathify'
import IncidentService from '../../services/IncidentService'

const state = {
  loading: false,
  incData: {
    Images: [],
    ExtraPublicInfo: [],
  },
  error: ""
}

const mutations = {
  ...make.mutations(state),
  SET_INCDATA (state, payload) {
    let _data = {}
    if (payload.Images === 'nan') {
      _data.Images = []
    } else {
      let _media = []
      for (let i=0; i != payload.Images.images.length; i++ ) {
        let tmp = payload.Images.images[i]
        _media.push({
          thumb: process.env.VUE_APP_BASE_URL + 'static' + tmp.thumbpath,
          src: process.env.VUE_APP_BASE_URL + 'static' + tmp.filepath,
          caption: (tmp.description ? tmp.description : 'n/a'),
          id: tmp.id 
        })
      }
      _data.Images = _media
    }
    if (payload.ExtraPublicInfo.newslinks === 'nan') {
      _data.ExtraPublicInfo = []
    } else {
      _data.ExtraPublicInfo = payload.ExtraPublicInfo.newslinks.split(",")
    }
    state.incData = _data
  }
}

const actions = {
    ...make.actions(state),
    async fetchIncData({commit}, entrycode) {
      try {
        commit('loading', true)
        let response = await IncidentService.getIncidentData(entrycode) // eslint-disable-line
        commit('SET_INCDATA', response.data)
        commit('loading', false)
      } catch (error) {
        commit('loading', false)
        commit('error', error)
      }
    },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
