export default {
    // primary: '#4caf50',
    primary: '#7EB09F',
    secondary: '#4caf50',
    teriary: '#82B1FF',
    accent: '#82B1FF',
    error: '#f55a4e',
    info: '#00d3ee',
    success: '5cb860',
    warning: '#ffa21a',
    general: '#2196F3',
    guacamole: '#7EB09F',
    biscuit: '#d9c7A3'
}