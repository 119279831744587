<template>
    <div>
        <v-card v-if="incident.id === currentId" class="mx-auto elevation-0" width="300px">
             <!-- <v-card-title> Top western road trips </v-card-title> -->
    <v-list three-line color="guacamole" class="cardgradient pa-0">
      <v-list-item>
        <v-list-item-avatar>
          <!-- <v-icon>mdi-pencil</v-icon> -->
          <!-- <v-img
            :src="birdIcon"
            /> -->

          <v-img
            :src="setCardIcon"
          />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title v-html="incident.country" class="font-weight-black"/>
          <!-- FIXME: place the location here -->
          <v-list-item-subtitle v-html="incident.region" class="grey--text text--darken-2 font-weight-bold" />
          <v-list-item-subtitle v-html="incident.text" align="right" class="pb-0 text-subtitle2 font-weight-black"/>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-card-text>
      <v-list dense>
        <v-list-item-title>{{ $t('popup.numberofanimalsinvolved')}} <span class="red--text">{{ incident.totalnumber }}</span></v-list-item-title>
      </v-list>

      <v-list>
        <v-row no-gutters>
          <v-col class="ma-0 pa-0">
            <v-list-item-title>{{ $t('popup.species') }}</v-list-item-title>
          </v-col>
          <v-col class="ma-0 pa-0">
            <template v-for="(animal, i) in species">
              <v-list-item-title class="guacamole--text" v-html="animal" :key="i"></v-list-item-title>
            </template>
          </v-col>
        </v-row>
      </v-list>

      <v-list>
        <v-list-item-title>{{ $t('popup.poisonbaitfound') }}<span class="red--text"> {{ incident.poisonBaitFound }}</span></v-list-item-title>
        <v-list-item-title>{{ $t('popup.antipoisondog') }}<span class="red--text"> {{ incident.antipoisonDogUnitUsed }}</span></v-list-item-title>
      </v-list>

      <v-list>
        <v-list-item-title>{{ $t('popup.complaint') }}<span class="red--text"> {{ incident.officialComplaint }}</span></v-list-item-title>
        <v-list-item-title>{{ $t('popup.court') }}<span class="red--text"> {{ incident.caseTakenToCourt }}</span></v-list-item-title>
      </v-list>
    <!-- <v-card-subtitle> 1,000 miles of wonder </v-card-subtitle> -->
    </v-card-text>

    <v-card-actions>
      <!-- <v-btn color="orange lighten-2" text> Explore </v-btn>

      <v-spacer></v-spacer> -->

      <v-btn @click="show = !show" block color="grey lighten-1" class="ma-0 pa-0">
        <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
      </v-btn>
    </v-card-actions>

    <v-expand-transition>
      <div v-show="show">
        <!-- <v-divider></v-divider> -->

        <v-card-text>
          <template v-if="newlinks.length > 0">
            <h4>Links:</h4>
            <ul>
              <li v-for="(link,i) in newlinks" :key="i">{{ link }}</li>
            </ul>
            <br>
          </template>
          <p v-else>No additional info</p>
          
          <template v-if="media.length > 0">
            <h4 class="pb-3">Photos</h4>
            <v-row 
              style="max-height: 300px"
              class="overflow-y-auto"
            >
              <v-col v-for="(image, index) in media" :key=index>
                <v-img height="100px" :src="image.thumb" @click=openGallery(index) />
              </v-col>
            </v-row>
          </template>

        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
    </div>
</template>

<script>
import * as Vue2Leaflet from 'vue2-leaflet'
import { get } from 'vuex-pathify'

export default {
    extends: Vue2Leaflet.LPopup,
    name: 'CrazyPopup',
    data: () => ({
        show: false,    
    }),
    props: {
        incident: Object,
    },
    methods: {
      openGallery (index) {
        this.$root.$emit("lightbox-open", index)
      }
    },
    computed: {
        ...get('incidents', {
          newlinks: 'incData@ExtraPublicInfo',
          images: 'incData@Images'
        }),
        media () {
          if (!this.images) return []
          
          return this.images
        },
        currentId() {
            return this.$store.state.app.currentID;
        },
        species () {
          if (!this.incident.TypeOfAnimal) return []
          return this.incident.TypeOfAnimal.split("#")
        },
        setCardIcon () {
          switch(this.incident.marker) {
            case 'bigbird':
              return require('@/assets/markers/cards/bigbird.png')
            case 'bigbird_unit':
              return require('@/assets/markers/cards/bigbird.png')
            case 'carnivores':
              return require('@/assets/markers/cards/carnivores.png')
            case 'carnivores_unit':
              return require('@/assets/markers/cards/carnivores.png')
            case 'dog':
              return require('@/assets/markers/cards/dog.png')
            case 'dog_unit':
              return require('@/assets/markers/cards/dog.png')
            case 'other':
              return require('@/assets/markers/cards/other.png')
            case 'other_unit':
              return require('@/assets/markers/cards/other.png')
            case 'multiple':
              return require('@/assets/markers/cards/multi.png')
            case 'multiple_unit':
              return require('@/assets/markers/cards/multi.png')
            default:
              return require('@/assets/markers/cards/unknown.png')
          }
        }
    },
}
</script>


<style scoped>
.cardgradient {
  background: linear-gradient(180deg, rgba(126,176,159,1) 0%, rgba(120,182,167,1) 50%, rgba(135,196,188,1) 100%);
}

.leaflet-popup-content {
  margin: 0;
}

.leaflet-popup-close-button {
  color: pink;
}
</style>