import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import i18n from './i18n'
import './plugins/chartist'
import './plugins/vuex-pathify'

import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';
// import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';

// highlightjs
import hljs from 'highlight.js';

VMdPreview.use(vuepressTheme, {
  Hljs: hljs,
});

Vue.use(VMdPreview);


// Required for the vue2-leaflet
import 'leaflet/dist/leaflet.css';

Vue.config.productionTip = false

// import axios from 'axios'

// axios.get(process.env.VUE_APP_V1_URL + 'index/')
//   .then(
//     (response) => {
//       store.commit('indexes/indexes', response.data)
//       new Vue({
//         i18n,
//         vuetify,
//         store,
//         methods: {
//           async setLanguage(language) {
//             // console.log('changed language to: ' + language)

//             const vm = this;
//             // FIXME: delete
//             // await this.$store.dispatch('pages/fetchPage', {location: 'about', locale: language})

//             localStorage.setItem('language', language)
//             document.documentElement.lang = language
//             vm.$i18n.locale = language
//             vm.$vuetify.lang.current = language
//           }
//         },
//         created () {
//           const vm = this
//           vm.setLanguage('en')
//         },
//         render: h => h(App)
//       }).$mount('#app')
//     }
//   )

store.dispatch('indexes/fetchIndexes')

new Vue({
  i18n,
  vuetify,
  store,
  methods: {
    async setLanguage(language) {
      const vm = this;

      localStorage.setItem('language', language)
      document.documentElement.lang = language
      vm.$i18n.locale = language
      vm.$vuetify.lang.current = language
    }
  },
  create () {
    const vm = this
    vm.setLanguage('en')
  },
  render: h => h(App)
}).$mount('#app')

// new Vue({
//   i18n,
//   vuetify,
//   store,
//   methods: {
//     setLanguage(language) {
//       const vm = this;

//       localStorage.setItem('language', language)
//       document.documentElement.lang = language
//       vm.$i18n.locale = language
//       vm.$vuetify.lang.current = language
//     }
//   },
//   created () {
//     const vm = this
//     vm.setLanguage('en')
//   },
//   render: h => h(App)
// }).$mount('#app')
