<template>
  <l-map :zoom="zoom" 
    :center="initialLocation" 
    :options="mapOptions"
    @ready="onReady"
  >
    <l-tile-layer
      url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
      :options="tileoptions"
    ></l-tile-layer>

    <!-- main parts of the map overlay -->
    <l-control-zoom position="bottomright"></l-control-zoom>
    <l-control position="topright">
      <a href="https://www.lifeneophron.eu/#home" target="_blank">
      <v-img
        :src="logo"
        width="200"
      />
      </a>
    </l-control>
    <l-control position="topright">
      <a href="https://balkandetoxlife.eu/" target="_blank">
      <v-img
        :src="balkan"
        width="170"
        />
      </a>
    </l-control>
    <v-marker-cluster
      @ready="ready"
    >
      <!-- <l-marker :lat-lng="markerLatLng"></l-marker> -->
      <l-marker
        v-for="l in locations"
        :key="l.id"
        :lat-lng="l.latlng"
        @click="setlala(l.id)"
        @popupopen="setpopup"
        :icon="setIcon(l)"
      >
        <!-- <l-popup>
          <custom-popup :incident="l"/>
        </l-popup> -->
        <crazy-popup :incident="l"  />
      </l-marker>
    </v-marker-cluster>

    <!-- TOP LEFT BUTTONS - INCIDENTS and REPORT -->
    <l-control position="topleft">
      <v-row class="pa-3 ml-6 pt-10">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn 
              v-on="on" 
              class="elevation-5 map-button2" 
              color="#efdd00"
              @click.prevent="openFilters"
            >
              {{ $t("front.buttonIncidents") }} 
            </v-btn>
          </template>
          <span>{{ $t("front.incidentTooltip") }}</span>
        </v-tooltip>

        <v-spacer class="ml-10" />

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn 
              v-on="on" 
              class="elevation-5 map-button2" 
              color="#efdd00"
              @click.stop="triggerReport"
            >
              {{ $t("front.buttonReport") }} 
            </v-btn>
          </template>
          <span>{{ $t("front.reportTooltip") }}</span>
        </v-tooltip>
      </v-row>
    </l-control>

    <!-- FILTER PANEL -->
    <l-control position="topleft" v-show="filterpanel">
        <v-card class="filtermenu ml-5" width="350px">
        <!-- <v-card> -->
          <v-card-title class="cardtitle justify-center mb-2">{{ $t("front.filtersTitle") }}</v-card-title>
          <v-card-text>
            <v-select 
              v-model="country" 
              :items="countries" 
              :label="$t('mapfilters.Country')"
              v-on:change="selcountry"
              background-color="white"
              filled
              dense
            />
            <v-select
              v-model="areaprotectionstatus"
              :items="area_classification"
              :label="$t('mapfilters.AreasProtectionStatus')"
              v-on:change="selareaprotectionstatus"
              background-color="white"
              filled
              dense
            />
            <v-select 
              v-model="typeofanimal"
              :items="animal_type" 
              :label="$t('mapfilters.TypeOfAnimal')"
              v-on:change="seltypeofanimal"
              background-color="white"
              filled
              dense
            />
            <v-select
              v-if="typeofanimal"
              v-model="animalspecies"
              :items="dynamic_species"
              label="Animal species"
              v-on:change="seltypeofspecies"
              background-color="white"
              filled
              dense
            />
            <v-select 
              v-model="baitfound"
              :items="bait" 
              :label="$t('mapfilters.PoisonBaitFound')"
              v-on:change="selbait"
              background-color="white"
              filled
              dense
            />
            <v-select
              v-model="dogunit"
              :items="antipoison_unit"
              :label="$t('mapfilters.AntipoisonDogUnitUsed')"
              v-on:change="seldogunit"
              background-color="white"
              filled
              dense
            />
            <v-select
              v-model="complaint"
              :items="official_complaint" 
              :label="$t('mapfilters.OfficialComplaint')" 
              v-on:change="selcomplaint"
              background-color="white"
              filled
              dense
            />
            <v-select 
              v-model="takentocourt" 
              :items="court_action" 
              :label="$t('mapfilters.CaseTakenToCourt')"
              v-on:change="seltakentocourt"
              background-color="white"
              filled
              dense
            />

            <!-- Date range -->
            <v-row>
              <v-col>
                <v-menu
                  v-model="datefromMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="datefromdisplay"
                      value="kalikantzaros"
                      :label="$t('mapfilters.DateFrom')"
                      persistent-hint
                      readonly
                      v-on="on"
                      append-icon="mdi-calendar"
                    />
                  </template>
                  <v-date-picker
                    v-model="datefrom"
                    :locale="locale"
                    min="1980-01-01"
                    max="2050-01-01"
                    @change="setdatefrom"
                    @input="datefromMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col>
                <v-menu
                  v-model="datetoMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="datetodisplay"
                      :label="$t('mapfilters.DateTo')"
                      persistent-hint
                      readonly
                      v-on="on"
                      :disabled="needDateFrom"
                      append-icon="mdi-calendar"
                    />
                  </template>
                  <v-date-picker
                    v-model="dateto"
                    :locale="locale"
                    min="1980-01-01"
                    max="2050-01-01"
                    @change="setdateto"
                    @input="datetoMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <p v-if="totalResults > 0" class="text-right">
              {{ $t('front.filtersResultNumber') }}: <span class="font-weight-black">{{ totalResults }}</span>
            </p>

            <p v-if="loading" class="text-left">
              processing <v-progress-circular indeterminate />
            </p>
          </v-card-text>

          <v-card-actions>
            <v-btn block color="primary" @click.stop="resetFilters" :disabled="!filtered">
              {{ $t("front.clearFilters")}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </l-control>

      <LightBox
        ref="lightbox"
        :media="media" 
        :show-capture="true"
        :show-light-box="false"
      />

  </l-map>
</template>

<script>
// had lpopup here
import {LMap, LTileLayer, LMarker, LControlZoom, LControl} from 'vue2-leaflet';
import { latLng, icon } from "leaflet";
import { get } from 'vuex-pathify'
import LightBox from 'vue-it-bigger'
import('vue-it-bigger/dist/vue-it-bigger.min.css')

// Custom Popup
// import CustomPopup from "@/components/CustomPopup.vue"
import CrazyPopup from "@/components/CrazyPopup.vue"

// missing icons
import { Icon } from 'leaflet';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// MarkerCluster experiments
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'

// FALLBACK
// import indexes from "@/assets/indexes.json";

// import store from '@/store'
// console.log(store)
// const indexes = store.state.indexes.indexes;
// let indexes = []

// Datetime experiments
import { DateTime } from "luxon";

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LControlZoom,
    LControl,
    // LPopup,
    'v-marker-cluster': Vue2LeafletMarkerCluster,
    // CustomPopup,
    CrazyPopup,
    LightBox
  },
  data () {
    let locations = [];
    for (let i = 0; i < this.$store.state.indexes.indexes.length; i++ ) {
      let loc = this.$store.state.indexes.indexes[i]
      locations.push({
        id: loc.entrycode ? loc.entrycode : 'na',
        latlng: latLng(loc.lat, loc.lng) ? latLng(loc.lat, loc.lng) : latLng(0,0),
        country: loc.country ? loc.country : 'na',
        region: loc.region ? loc.region : 'na',
        poisonBaitFound: loc.poisonBaitFound ? loc.poisonBaitFound : 'na',
        antipoisonDogUnitUsed: loc.antipoisonDogUnitUsed ? loc.antipoisonDogUnitUsed: 'na',
        officialComplaint: loc.officialComplaint ? loc.officialComplaint : 'na',
        caseTakenToCourt: loc.caseTakenToCourt ? loc.caseTakenToCourt : 'na',
        areaProtectionStatus: loc.areaProtectionStatus ? loc.areaProtectionStatus : 'na',
        // TypeOfAnimal: loc.TypeOfAnimal,
        TypeOfAnimal: loc.species ? loc.species : 'na',
        text: loc.dateOfEvent ? loc.dateOfEvent : 'na',
        // dateOfEvent: DateTime.fromFormat(loc.dateOfEvent, 'dd/MM/yyyy'),
        dateOfEvent: loc.dateOfEvent ? DateTime.fromFormat(loc.dateOfEvent, 'dd/MM/yyyy') : 'na',
        totalnumber: loc.totalnumber,
        marker: loc.marker
      })
    }

    const initLocations = [...locations]

    return {
      loading: false,
      locations,
      initLocations,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a target="_blank" href="https://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 6,
      center: [51.505, -0.159],
      initialLocation: latLng(38.682613, 22.660929),
      markerLatLng: [51.504, -0.159],
      tileoptions: { useCache: true },
      mapOptions: {
        zoomSnap: 0.5,
        zoomControl: false
      },
      superlala: 0,
      logo: require('@/assets/logo_smaller.png'),
      balkan: require('@/assets/logo_balkandetox.png'),

      // Icons for markers on map
      bigbirdicon: icon({
        iconUrl: require('@/assets/markers/bigbird.png'),
        iconSize: [30, 35],
        iconAnchor: [15, 35],
        popupAnchor: [0, -35],
      }),
      bigbirduniticon: icon({
        iconUrl: require('@/assets/markers/bigbird_unit.png'),
        iconSize: [30, 35],
        iconAnchor: [15, 35],
        popupAnchor: [0, -35],
      }),
      carnivoresicon: icon({
        iconUrl: require('@/assets/markers/carnivores.png'),
        iconSize: [30, 35],
        iconAnchor: [15, 35],
        popupAnchor: [0, -35],
      }),
      carnivoresuniticon: icon({
        iconUrl: require('@/assets/markers/carnivores_unit.png'),
        iconSize: [30, 35],
        iconAnchor: [15, 35],
        popupAnchor: [0, -35],
      }),
      dogicon: icon({
        iconUrl: require('@/assets/markers/dog.png'),
        iconSize: [30, 35],
        iconAnchor: [15, 35],
        popupAnchor: [0, -35],
      }),
      doguniticon: icon({
        iconUrl: require('@/assets/markers/dog_unit.png'),
        iconSize: [30, 35],
        iconAnchor: [15, 35],
        popupAnchor: [0, -35],
      }),
      othericon: icon({
        iconUrl: require('@/assets/markers/other.png'),
        iconSize: [30, 35],
        iconAnchor: [15, 35],
        popupAnchor: [0, -35],
      }),
      otheruniticon: icon({
        iconUrl: require('@/assets/markers/other_unit.png'),
        iconSize: [30, 35],
        iconAnchor: [15, 35],
        popupAnchor: [0, -35],
      }),
      multipleicon: icon({
        iconUrl: require('@/assets/markers/multiple.png'),
        iconSize: [30, 35],
        iconAnchor: [15, 35],
        popupAnchor: [0, -35],
      }),
      multipleuniticon: icon({
        iconUrl: require('@/assets/markers/multiple_unit.png'),
        iconSize: [30, 35],
        iconAnchor: [15, 35],
        popupAnchor: [0, -35],
      }),

      // Filter panel properties
      filterpanel: false,
      filtered: false,  // tracks the state of filtering
      filters: {
        country: "",
        poisonBaitFound: "",
        antipoisonDogUnitUsed: "",
        caseTakenToCourt: "",
        areaProtectionStatus: "",
        TypeOfAnimal: "",
        speciesfilter: "",
      },
      country: "",
      baitfound: "",
      dogunit: "",
      complaint: "",
      takentocourt: "",
      areaprotectionstatus: "",
      typeofanimal: "",
      animalspecies: "",
      datefrom: "",
      datefromdisplay: "",
      dateto: "",
      datetodisplay: "",
      datefromMenu: false,
      datetoMenu: false,
      totalResults: 0,
      needDateFrom: true,
      datefilter: false,

      // FILTER PANEL DATA
      countries: [
        "Albania",
        "Bosnia and Herzegovina",
        "Bulgaria",
        "Croatia",
        "Greece",
        "North Macedonia", 
        "Serbia"
      ],
      region: {
        Greece: ["Attiki", "Ionia Nisia", "Kriti"],
      },
      area_classification: [
        "National Park",
        "Nature park",
        "Natural reserve",
        "Controlled hunting area",
        "Wildlife sanctuary",
        "SPA",
        "SCI",
        "SPA/SCI",
        "Ramsar",
        "IBA",
        "Biosphere reserve",
        "None",
        "Unknown",
      ],
      animal_type: [
        "Bird",
        "Cat",
        "Dog",
        "Farm animal",
        "Impossible to identify",
        "Reptile",
        "Insect",
        "Wild mammal",
      ],
      bait: ["Yes", "No", "Unknown"],
      antipoison_unit: ["Yes", "No", "Unknown"],
      official_complaint: ["Yes", "No", "Unknown"],
      court_action: ["Yes", "No", "Unknown", "Not Applicable"],
      dialogosItems: ["Yes", "No", "I don't know"],
      dialogosBait: ["Yes", "No", "I don't know"],
    };
  },
  computed: {
    // ...get('indexes', [
    //   'indexes'
    // ]),
    ...get('incidents', {
      media: 'incData@Images'
    }),
    locale() {
      return this.$i18n.locale
    },
    dynamic_species () {
      let ret = ''
      switch(this.typeofanimal) {
        case 'Bird':
          ret = [
            "Accipiter brevipes",
            "Accipiter gentilis",
            "Accipiter nisus",
            "Aegolius funereus",
            "Aegypius monachus",
            "Aquila chrysaetos",
            "Aquila clanga",
            "Aquila heliaca",
            "Aquila nipalensis",
            "Aquila pomarina",
            "Asio flammeus",
            "Asio otus",
            "Athene noctua",
            "Bubo bubo",
            "Buteo buteo",
            "Buteo lagopus",
            "Buteo rufinus",
            "Circaetus gallicus",
            "Circus aeruginosus",
            "Circus cyaneus",
            "Circus macrourus",
            "Circus pygargus",
            "Elanus caeruleus",
            "Falco biarmicus",
            "Falco cherrug",
            "Falco columbarius",
            "Falco eleonorae",
            "Falco naumanni",
            "Falco pelegrinoides",
            "Falco peregrinus",
            "Falco subbuteo",
            "Falco tinnunculus",
            "Falco vespertinus",
            "Glaucidium passerinum",
            "Gypaetus barbatus",
            "Gyps fulvus",
            "Haliaeetus albicilla",
            "Hieraaetus fasciatus",
            "Hieraaetus pennatus",
            "Milvus migrans",
            "Milvus milvus",
            "Neophron percnopterus",
            "Otus scops",
            "Pandion haliaetus",
            "Pernis apivorus",
            "Raptor spp",
            "Strix aluco",
            "Tyto alba",
          ]
          break;
        case 'Cat':
          ret = [
            'Felis catus'
          ]
          break;
        case 'Dog':
          ret = [
            "Hunting dog",
            "Shepherd dog",
            "Truffle dog",
          ]
          break;
        case 'Farm animal':
          ret = [
            "Chicken",
            "Cow",
            "Donkey",
            "Goat",
            "Horse",
            "Other",
            "Pig",
            "Sheep",
            "Turkey",
          ]
          break;
        case 'Impossible to identify':
          ret = [
            'none'
          ]
          break;
        case 'Reptile':
          ret = [
            "Other reptile",
            "Testudo graeca",
            "Testudo hermanni",
            "Testudo marginata"
          ]
          break;
        case 'Insect':
          ret = [
            "Bee",
            "Other insect",
            "Wasp",
          ]
          break;
        case 'Wild mammal':
          ret = [
            "Canis aureus",
            "Canis lupus",
            "Lynx Lynx",
            "Ursus arctos",
            "Vulpes vulpes",
            "Erinaceus concolor",
            "Felis silvestris",
            "Large mammal spp",
            "Martes foina",
            "Martes martes",
            "Meles meles",
            "Mustela nivalis",
            "Other mammal",
            "Small mammal spp",
            "Sus scrofa",
          ]
          break;
        default:
          ret = ['none']
          break;
      }
      return ret
    }
  },
  methods: {
    // skata(e) {
    //   console.log('paparia mantoles')
    //   console.log(e)
    //   this.$store.commit('setCurrentID', e)
    //   this.$nextTick(() => {
    //     e.target.openPopup()
    //   })
    // },
    setpopup: async function (e) {      
      // get the incident data
      this.$store.dispatch('incidents/fetchIncData', parseInt(this.superlala))

      // console.log(e)
      this.$store.commit('app/setCurrentID', this.superlala)
      this.$nextTick(() => {
          e.target.openPopup()
          // console.log('after the tick!')
      })
    },
    setlala(e) {
      // console.log('increase the key')
      this.superlala = e
    },
    ready() {
      //this.$emit('showDisclaimer', true)
    },
    setIcon(item) {
      switch(item.marker) {
        case 'bigbird':
          return this.bigbirdicon
        case 'bigbird_unit':
          return this.bigbirduniticon
        case 'carnivores':
          return this.carnivoresicon
        case 'carnivores_unit':
          return this.carnivoresuniticon
        case 'dog':
          return this.dogicon
        case 'dog_unit':
          return this.doguniticon
        case 'other':
          return this.othericon
        case 'other_unit':
          return this.otheruniticon
        case 'multiple':
          return this.multipleicon
        case 'multiple_unit':
          return this.multipleuniticon
      }
    },
    onReady() {
      this.$emit("showDisclaimer", true)
    },
    triggerReport () {
      // FIXME: trigger the event for the showReport
      this.$emit("showReport", true)
    },
    openFilters () {
      let open = this.filterpanel ? false : true
      this.filterpanel = open
    },
    resetFilters () {
      this.loading = true
      // Reset filters array
      this.filters = {
        country: "",
        poisonBaitFound: "",
        antipoisonDogUnitUsed: "",
        officialComplaint: "",
        caseTakenToCourt: "",
        areaProtectionStatus: "",
        TypeOfAnimal: "",
        speciesfilter: ""
      };

      // Reset the select models
      this.country = "";
      this.baitfound = "";
      this.dogunit = "";
      this.complaint = "";
      this.takentocourt = "";
      this.areaprotectionstatus = "";
      this.typeofanimal = "";
      this.dateto = "";
      this.datefrom = "";
      this.animalspecies = "";

      // Reset the date fields
      this.datefromdisplay = "";
      this.datetodisplay = "";

      // Reset the locations array
      this.locations = [...this.initLocations];

      // Reset the filtered state flag
      this.filtered = false;
      this.needDateFrom = true;
      this.datefilter = false

      // Reset the total Results
      this.totalResults = 0;
      this.loading = false
    },
    selcountry(evt) {
      this.loaading = true;
      this.filtered = true;
      this.filters.country = evt;
      this.filterDateRange();
    },
    selbait(evt) {
      this.loaading = true;
      this.filtered = true;
      this.filters.poisonBaitFound = evt;
      this.filterDateRange();
    },
    seldogunit(evt) {
      this.loaading = true;
      this.filtered = true;
      this.filters.antipoisonDogUnitUsed = evt;
      this.filterDateRange();
    },
    selcomplaint(evt) {
      this.loaading = true;
      this.filtered = true;
      this.filters.officialComplaint = evt;
      this.filterDateRange();
    },
    seltakentocourt(evt) {
      this.loaading = true;
      this.filtered = true;
      this.filters.caseTakenToCourt = evt;
      this.filterDateRange();
    },
    selareaprotectionstatus(evt) {
      this.loading = true;
      this.filtered = true;
      this.filters.areaProtectionStatus = evt;
      this.filterDateRange();
    },
    seltypeofanimal(evt) {
      this.loaading = true;
      this.filtered = true;
      this.filters.TypeOfAnimal = evt;
      this.filterDateRange();
    },
    seltypeofspecies(evt) {
      this.loading = true;
      this.filtered = true;
      this.filters.speciesfilter = evt;
      this.filterDateRange();
    },
    filterlocations () {
      this.locations = [...this.initLocations];
      this.locations = this.locations.filter((item) => {
        for (var [key, value] of Object.entries(this.filters)) {
          if (key === "TypeOfAnimal" && value !== "") {
            if (item[key].includes(value) === false) {
              return false;
            }
          }
          
          if (value !== "" && (item[key] === undefined || item[key] != value )) {
            if (key == "TypeOfAnimal" && value !== "") {
              return true;
            }
            return false;
          }
        }
        return true;
      });
      this.totalResults = this.locations.length
    },
    filterspecies () {
      this.locations = this.locations.filter((item) => {
        if (item['TypeOfAnimal'].includes(this.animalspecies) === true) {
          return true
        }
      })
      this.totalResults = this.locations.length
    },
    setdatefrom(evt) {
      // console.log("we have the date from");
      // console.log(evt)

      // Apply date formatting as required
      this.datefromdisplay = this.formatDate(evt)
      
      // enable the form for the From
      this.needDateFrom = false

      // if datefilter is applied, we already have
      // a filter in the To field.
      // so refresh the filters
      if (this.datefilter) this.filterDateRange()
    },
    setdateto(evt) {
      // console.log("we have the date to")
      // console.log(evt)

      // Apply date formatting as required
      this.datetodisplay = this.formatDate(evt)

      this.datefilter = true
      this.filterDateRange()
    },
    filterDateRange() {
      // reset the previous set filters
      this.filterlocations()
      // console.log('FILTERING DATES')

      if (this.animalspecies) {
        this.filterspecies()
      }

      if (this.datefilter) {
        this.filtered = true
        let start = new Date(this.datefrom)
        let end = new Date(this.dateto)

        this.locations =  this.locations.filter(item => {
          let date = new Date(item.dateOfEvent);
          return date >= start && date <= end;
        })
        this.totalResults = this.locations.length
      }
      this.loading = false
    },
    formatDate (date) {
      if(!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    }
  },
  mounted () {
    // event fired from within CrazyPopup
    this.$root.$on('lightbox-open', (id) => {
      this.$refs.lightbox.showImage(id)
    })
  }
}
</script>

<style scoped>
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";

.map-button {
  background: linear-gradient(0deg, rgba(126,176,159,1) 0%, rgba(135,196,188,1) 100%);
  border-color: #7eb09f;
  border: 2px solid;
}

.map-button2 {
  background: linear-gradient(0deg, rgba(255,230,54,1) 0%, rgba(248,255,142,1) 100%);
  border-color: #efdd00;
  border: 2px solid;
}

.filtermenu {
  z-index: 1305;
  background-color: #f1ebe0;
}

.cardtitle {
  background-color: #d9c7A3;
}
</style>