<template>
    <v-menu class="toolbar-menu-item rounded-0" 
        offset-y origin="center center" 
        :nudge-bottom="10" 
        transition="slide-x-transition" 
        content-class="main-menu" 
        z-index=1301
    >
        <template v-slot:activator="{ on }">
        <v-btn icon large text v-on="on" :ripple="false" color="white darken-2" x-large>
            <v-icon>mdi-menu</v-icon>
        </v-btn>
        </template>
        <v-card
            class="rounded-0"
            color="#f1ebe0"
        >
            <!-- <v-card-title class="cardtitle mb-2 justify-center">Menu</v-card-title> -->
            <v-toolbar color="#d9c7A3" class="cardtitle justify-center mb-2">
                <v-spacer />
                <v-toolbar-title>   
                    {{ $t('menu.title') }}
                </v-toolbar-title>
                <v-spacer />
            </v-toolbar>
            <v-card-text>
                <!-- the iterative way has issues due to the caching of the data FIXME -->
                <!-- <v-list color="transparent">
                    <template v-for="(item,i) in items">
                        <span v-if="item.spacer" :key="i" class="py-2">&nbsp;</span>
                        <v-list-item v-else :key="i" @click="clickedOn(item)" style="background-color: white; background-opacity: 0.5" class="ma-1">
                            <v-list-item-content>
                            <v-list-item-title>{{ item.text }}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-icon>
                                <v-icon v-text="item.icon" />
                            </v-list-item-icon>
                        </v-list-item>
                    </template>
                </v-list> -->
                <v-list color="transparent">
                    <v-list-item @click="clickedOn('about')" style="background-color: white; background-opacity: 0.5" class="ma-1">
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('menu.about') }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                            <v-icon>mdi-information-outline</v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                    <v-list-item @click="clickedOn('wildlifePoisoning')" style="background-color: white; background-opacity: 0.5" class="ma-1">
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('menu.wildlifepoisoning') }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                            <v-icon>mdi-help</v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                    <v-list-item @click="clickedOn('antipoison')" style="background-color: white; background-opacity: 0.5" class="ma-1">
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('menu.antipoison') }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                            <v-icon>mdi-dog-side</v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                    <v-list-item @click="clickedOn('majorPoisonIncidents')" style="background-color: white; background-opacity: 0.5" class="ma-1">
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('menu.majorpoisonincidents') }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                            <v-icon>mdi-bone</v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                    <span class="py-2">&nbsp;</span>
                    <v-list-item @click="clickedOn('links')" style="background-color: white; background-opacity: 0.5" class="ma-1">
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('menu.links') }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                            <v-icon>mdi-link-variant</v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                    <v-list-item @click="clickedOn('statistics')" style="background-color: white; background-opacity: 0.5" class="ma-1">
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('menu.statistics') }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                            <v-icon>mdi-chart-box-outline</v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                    <span class="py-2">&nbsp;</span>
                    <v-list-item @click="clickedOn('organizations')" style="background-color: white; background-opacity: 0.5" class="ma-1">
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('menu.organizations') }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                            <v-icon>mdi-web</v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                </v-list>

            </v-card-text>

            <v-card-actions>
            </v-card-actions>
        </v-card>
    </v-menu>
</template>

<script>
import i18n from '@/i18n'

export default {
    name: "TopBurgerMenu",
    data() {
        return {
            items: [
                {
                    text: this.$i18n.t('menu.about'),
                    // text: this.about(),
                    // text: i18n.t('menu.about'),
                    location: 'about',
                    icon: 'mdi-information-outline'
                    // img: require('@/assets/markers/icon_menu_about.png')
                },
                {
                    // text: this.$i18n.t('menu.wildlifepoisoning'),
                    // text: this.wildlifepoisoning,
                    text: i18n.t('menu.wildlifepoisoning'),
                    location: 'wildlifePoisoning',
                    icon: 'mdi-help'
                },
                {
                    text: this.$i18n.t('menu.antipoison'),
                    location: 'antipoison',
                    icon: 'mdi-dog-side'
                },
                {
                    text: this.$i18n.t('menu.majorpoisonincidents'),
                    location: 'majorPoisonIncidents',
                    icon: 'mdi-bone'
                },
                // FIXME: see how to add some space in between the logical groups
                // {
                //     text: "",
                //     location: "",
                //     icon: ""
                // },                
                // {
                //     text: 'Resources',
                //     location: 'resources'
                // },
                {
                    spacer: true,
                    text: '',
                    location: '',
                    icon: ''
                },
                {
                    text: this.$i18n.t('menu.links'),
                    location: 'links',
                    icon: 'mdi-link-variant'
                },
                {
                    text: this.$i18n.t('menu.statistics'),
                    location: 'statistics',
                    icon: 'mdi-chart-box-outline'
                },
                {
                    spacer: true,
                    text: '',
                    location: '',
                    icon: ''
                },
                {
                    text: this.$i18n.t('menu.organizations'),
                    location: 'organizations',
                    icon: 'mdi-web'
                }
            ],
        }
    },
    computed: {
    },
    methods: {
        async clickedOn(item) {
            // this.$emit("gotoPage", item.location)
            await this.$store.dispatch('pages/fetchPage', {location: item, locale: this.$vuetify.lang.current})
            this.$emit("gotoPage", item)
        }
    }
}
</script>


<style scoped>
.main-menu {
    /* border-radius: 25px; */
    width: 400px;
    margin-right: 10px;
}

.cardtitle {
    background-color: #d9c7A3;
}
</style>
